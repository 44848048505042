import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import SanityBlockContent from '@sanity/block-content-to-react';

import { Header } from '../components/Header';
import { TextSection } from '../components/TextSection';
import { serializers } from '../utils/serializer';

export default function GuidelinesPage({ data }) {
  const sanityContent = data.guidelinespage.contentSections;

  const LastPartOfSanityContent = sanityContent.slice(1);

  return (
    <GuidelinesContainer>
      <GuidelinesContent>
        <TextSection>
          <Header big bolder>
            {sanityContent[0].title}
          </Header>
          <SanityBlockContent
            blocks={sanityContent[0]._rawText}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </TextSection>

        {LastPartOfSanityContent.map((content, index) => (
          <TextSection key={index}>
            <Header>{content.title}</Header>
            <SanityBlockContent
              blocks={content._rawText}
              serializers={serializers}
              projectId={process.env.SANITY_PROJECT_ID}
              dataset={process.env.SANITY_DATASET}
            />
          </TextSection>
        ))}
      </GuidelinesContent>
    </GuidelinesContainer>
  );
}

export const query = graphql`
  query GuidelinesQuery {
    guidelinespage: sanityPage(
      id: { eq: "-81e7db66-2db1-5412-a1fa-f422e65f9eb1" }
    ) {
      contentSections {
        ... on SanityTextSection {
          title
          subtitle
          _rawText
        }
      }
    }
  }
`;

const GuidelinesContainer = styled.div``;

const GuidelinesContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  position: relative;
  flex: 1;

  @media (min-width: 1080px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto;
    padding: 8.6rem 0 2rem 0;
  }
`;
